import './spiner.scss';
function Spiner() {
	return (
		<div className="lds-ripple">
			<div></div>
			<div></div>
		</div>
	);
}

export default Spiner;
